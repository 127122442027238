<template>
  <div
    v-if="!mobile"
    class="flex flex-col bg-filters relative commentsContainer"
  >
    <loader :loading="loading" />

    <div class="flex w-full h-full overflow-hidden">
      <div class="w-full overflow-hidden relative">
        <div class="flex flex-col h-full">
          <div
            v-if="messages.length > 0"
            class="h-full overflow-y-auto overflow-x-hidden p-2 flex flex-col-reverse justify-end"
          >
            <transition-group
              class="min-h-full flex flex-col justify-end"
              name="fadeAndSlide"
              tag="div"
            >
              <div class="" :key="'key-' + post.id" v-for="post in messages">
                <div
                  class="box mb-2 py-2 relative flex flex-col"
                  style="min-height: 9rem"
                >
                  <div class="flex items-center mb-2">
                    <div class="h-14 flex flex-col justify-between items-start">
                      <span class="text-beige text-xs">
                        {{
                          [post.date, "YYYY-MM-DD"] | moment("DD / MM / YYYY")
                        }}
                      </span>

                      <span
                        class="text-xs text-font-light font-thin uppercase"
                        >{{ post.commentable_type }}</span
                      >

                      <span
                        class="font-semibold text-sm cursor-pointer"
                        @click="gotoproject(post.commentable_type)"
                        >{{ post.commentable_name }}</span
                      >
                    </div>

                    <div
                      class="h-auto absolute top-0 right-0 flex flex-row mt-2 mr-2"
                    >
                      <div
                        class="ml-auto header-btn-gray"
                        @click="selectedEdit = post.id"
                      >
                        <i class="mdi mdi-pencil-outline"></i>
                      </div>

                      <div
                        class="header-btn-gray"
                        @click="selectedDelete = post.id"
                      >
                        <i class="mdi mdi-trash-can-outline"></i>
                      </div>
                    </div>
                  </div>

                  <div class="mb-2 text-font-light font-thin">
                    {{ post.commentary }}
                  </div>

                  <div class="flex flex-row justify-end items-center mt-auto">
                    <!--                                        <el-tooltip class="item" effect="dark" :content="post.user_name" placement="top"> -->

                    <img
                      v-if="post.image"
                      class="h-8 w-8 mr-4 rounded-full bg-cover bg-center shadow-xl"
                      :src="profilePhoto(post)"
                      alt="img"
                    />

                    <div
                      v-else
                      class="w-auto px-2 flex flex-col justify-center items-center"
                    >
                      <vue-initials-img
                        :name="post.user_name"
                        class="rounded-full h-8"
                      />
                    </div>

                    <!-- </el-tooltip>-->
                  </div>

                  <transition name="listSubCats">
                    <div
                      class="absolute h-full w-full bg-gradient-module top-0 left-0 rounded-lg overflow-hidden"
                      v-if="selectedDelete === post.id"
                    >
                      <div
                        class="header-btn-gray absolute right-0 mr-2 top-0 mt-2 z-40"
                        @click="selectedDelete = false"
                      >
                        <i class="mdi mdi-close"></i>
                      </div>
                      <div class="center-absolute">
                        <loader :loading="loadingDelete" />
                        <div class="mb-2">Are you Sure?</div>
                        <div>
                          <div
                            @click="deleteComment(post.id)"
                            class="btn-danger inline-block mr-2"
                          >
                            Delete
                          </div>
                          <div
                            @click="selectedDelete = false"
                            class="btn-gray inline-block"
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>

                  <transition name="listSubCats">
                    <div
                      class="absolute h-full w-full bg-gradient-module top-0 left-0 rounded-lg overflow-hidden"
                      v-if="selectedEdit === post.id"
                    >
                      <div
                        class="header-btn-gray absolute right-0 mr-2 top-0 mt-2 z-40"
                        @click="selectedEdit = false"
                      >
                        <i class="mdi mdi-close"></i>
                      </div>
                      <div class="center-absolute p-4 flex items-end">
                        <textarea
                          class="border bg-gradient-module w-full mr-2 h-24 outline-none p-2 rounded-lg shadow-lg"
                          placeholder="Send message..."
                          v-model="post.commentary"
                        ></textarea>
                        <div
                          class="btn-aux inline-block"
                          @click="editComment(post)"
                        >
                          <i class="mdi mdi-send"></i>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </transition-group>
          </div>

          <div v-else-if="!loading" class="w-full h-full overflow-hidden">
            <div
              class="h-full w-full flex flex-col justify-center items-center bg-grey-lighter"
            >
              <span class="text-font-gray">No Comments</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-20 bg-font-gray-15 overflow-auto flex flex-col relative"
        v-if="true"
      >
        <loader :loading="loadUsers" />

        <div
          class="flex flex-col flex-1 py-2 overflow-auto no-scrollbar tooltipContainer"
        >
          <div
            class="mb-2 flex flex-col justify-center items-center !cursor-pointer"
            v-for="(user, index) in users.data"
            :key="index"
            v-tooltip="{
              content: user.user_name,
              placement: 'bottom',
              theme: 'tooltip',
              arrow: false,
              triggers: ['hover', 'focus', 'touch'],
              shift: true,
              'prevent-overflow': true,
              'arrow-padding': 0,
            }"
            @click="addUsers(user.id)"
          >
            <!--                                    <el-tooltip class="item" effect="dark" :content="user.user_name" placement="top">-->

            <div
              class="flex flex-row justify-center items-center relative z-0 !pointer-events-none relative cursor-default no-"
            >
              <div
                v-if="if_in_commentaries(user.id)"
                class="absolute top-0 right-0 h-4 w-4 -mt-1 -mr-1 rounded-full bg-success flex flex-col justify-center items-center shadow-xl !pointer-events-none"
              >
                <i
                  class="mdi mdi-check-bold text-white text-xxs !pointer-events-none"
                />
              </div>

              <img
                v-if="user.image"
                class="flex h-10 w-10 rounded-full bg-cover bg-center shadow-xl z-0 !pointer-events-none"
                :src="profilePhoto(user)"
                alt="img"
              />

              <div
                v-else
                class="w-full flex flex-col justify-center items-center z-0 pointer-events-none"
              >
                <vue-initials-img
                  :name="user.user_name"
                  class="flex rounded-full h-10 w-10 z-0 !pointer-events-none"
                />
              </div>
            </div>

            <!--                        </el-tooltip>-->
          </div>
        </div>

        <separator />

        <div
          class="h-32 bg-module-top py-2 flex flex-col justify-between items-center"
        >
          <div
            class="h-auto flex flex-col justify-center items-center relative"
          >
            <div
              v-if="projects"
              class="h-4 w-4 rounded-full absolute bg-success top-0 right-0 flex flex-col justify-center items-center"
            >
              <i class="mdi mdi-check-bold text-xs"></i>
            </div>
            <div
              class="h-12 w-12 rounded-full bg-filters flex flex-col justify-center items-center shadow cursor-pointer"
              @click="projects = !projects"
            >
              <span class="text-lg text-white font-semibold">PR</span>
            </div>
          </div>

          <div
            class="h-auto flex flex-col justify-center items-center relative"
          >
            <div
              v-if="oportunities"
              class="h-4 w-4 rounded-full absolute bg-success top-0 right-0 flex flex-col justify-center items-center"
            >
              <i class="mdi mdi-check-bold text-xs"></i>
            </div>
            <div
              class="h-12 w-12 rounded-full bg-filters flex flex-col justify-center items-center shadow cursor-pointer"
              @click="oportunities = !oportunities"
            >
              <span class="text-lg text-white font-semibold">OP</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-else-if="!loading" class="flex w-full h-full overflow-hidden">
            <div class="flex flex-col justify-center bg-grey-lighter">
                <div class="text-grey-darker text-center bg-grey-light px-4 py-2 m-2">
                    <div class="module-title">No hay comentarios</div>
                </div>
            </div>
        </div> -->

    <separator />

    <transition name="fadeInOut">
      <div class="" v-if="$route.params.projectId">
        <div class="p-4 flex items-end">
          <textarea
            class="bg-gradient-module w-full mr-2 h-24 outline-none p-2 rounded-lg shadow-lg"
            placeholder="Send message..."
            v-model="message"
          ></textarea>
          <div class="btn-aux" @click="publishComment()">
            <i class="mdi mdi-send"></i>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <div v-else class="flex flex-col bg-filters relative">
    <loader :loading="loading" />

    <div class="flex w-full h-full overflow-hidden">
      <div class="w-full overflow-hidden">
        <div class="flex flex-col h-full">
          <div
            v-if="messages.length > 0"
            class="h-full overflow-y-auto mobile-scrollbar overflow-x-hidden p-2 flex flex-col-reverse justify-end"
          >
            <transition-group
              class="min-h-full flex flex-col justify-end"
              name="fadeAndSlide"
              tag="div"
            >
              <div class="" :key="'key-' + post.id" v-for="post in messages">
                <div
                  class="box mb-2 py-2 relative flex flex-col"
                  style="min-height: 9rem"
                >
                  <div
                    class="flex items-center mb-2 flex flex-row justify-start items-center"
                  >
                    <div
                      class="flex flex-col h-10 flex flex-col justify-between"
                    >
                      <span class="text-beige text-3xs">
                        {{
                          [post.date, "YYYY-MM-DD"] | moment("DD / MM / YYYY")
                        }}
                      </span>

                      <span class="text-white uppercase text-3xs">{{
                        post.commentable_type
                      }}</span>

                      <div class="font-semibold text-xxs">
                        {{ post.commentable_name }}
                      </div>
                    </div>

                    <div class="absolute top-0 right-0 flex flex-row mt-2 mr-2">
                      <div
                        class="ml-auto h-5 w-5 rounded-md flex-none header-btn-gray"
                        @click="selectedEdit = post.id"
                      >
                        <i class="text-xs mdi mdi-pencil-outline"></i>
                      </div>

                      <div
                        class="h-5 w-5 rounded-md flex-none header-btn-gray"
                        @click="selectedDelete = post.id"
                      >
                        <i class="text-xs mdi mdi-trash-can-outline"></i>
                      </div>
                    </div>

                    <!-- <div class="h-6 w-6 flex-none header-btn" @click="goto('ProjectsProjects',post.commentable_id)">
                                                <i class="text-xs mdi mdi-share" ></i>
                                            </div> -->
                  </div>

                  <div class="mb-2 text-xs text-font-light font-thin">
                    {{ post.commentary }}
                  </div>
                  <div
                    v-tooltip="{
                      content: post.user_name,
                      placement: 'bottom',
                      theme: 'tooltip',
                      arrow: false,
                      triggers: ['hover', 'focus', 'touch'],
                      shift: true,
                      'prevent-overflow': true,
                      'arrow-padding': 0,
                    }"
                    class="flex flex-row justify-end items-center items-center mt-auto"
                  >
                    <!--                                                                <el-tooltip class="item" effect="dark" :content="post.user_name" placement="top">-->
                    <img
                      v-if="post.image"
                      class="h-6 w-6 mr-4 rounded-full bg-cover bg-center shadow-xl"
                      :src="profilePhoto(post)"
                      alt="img"
                    />

                    <div
                      v-else
                      class="w-auto px-2 flex flex-col justify-center items-center"
                    >
                      <vue-initials-img
                        :name="post.user_name"
                        class="rounded-full h-6"
                      />
                    </div>

                    <!--                                            </el-tooltip>-->
                  </div>

                  <transition name="listSubCats">
                    <div
                      class="absolute h-full w-full bg-gradient-module top-0 left-0 rounded-lg overflow-hidden"
                      v-if="selectedDelete == post.id"
                    >
                      <div
                        class="header-btn-gray absolute h-6 w-6 right-0 mr-2 top-0 mt-2 z-40"
                        @click="selectedDelete = false"
                      >
                        <i class="text-xs mdi mdi-close"></i>
                      </div>
                      <div class="center-absolute">
                        <div class="mb-2">Are you Sure?</div>
                        <div>
                          <div
                            @click="deleteComment(post.id)"
                            class="btn-danger h-6 flex flex-col justify-center items-center mx-auto w-10/12 mb-2 inline-block"
                          >
                            Delete
                          </div>
                          <div
                            @click="selectedDelete = false"
                            class="btn-gray h-6 flex flex-col justify-center items-center mx-auto w-10/12 inline-block"
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>

                  <transition name="listSubCats">
                    <div
                      class="absolute h-full w-full bg-gradient-module top-0 left-0 rounded-lg overflow-hidden"
                      v-if="selectedEdit === post.id"
                    >
                      <div
                        class="header-btn-gray absolute h-6 w-6 right-0 mr-2 top-0 mt-2 z-40"
                        @click="selectedEdit = false"
                      >
                        <i class="mdi mdi-close text-xs"></i>
                      </div>
                      <div
                        class="center-absolute p-4 flex flex-col items-end h-full"
                      >
                        <textarea
                          class="border bg-gradient-module w-full mb-2 mt-4 h-full outline-none p-2 rounded-lg shadow-lg text-sm"
                          placeholder="Send message..."
                          v-model="post.commentary"
                        ></textarea>

                        <div
                          class="btn-aux inline-block mt-auto h-6 flex flex-col justify-center items-center mx-auto w-full"
                          @click="editComment(post)"
                        >
                          <i class="mdi mdi-send"></i>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </transition-group>
          </div>

          <div v-else-if="!loading" class="w-full h-full overflow-hidden">
            <div
              class="h-full w-full flex flex-col justify-center items-center bg-grey-lighter"
            >
              <span class="text-font-gray text-xs">No Comments</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="w-10 bg-font-gray-15 py-2 overflow-auto flex flex-col justify-start items-center" v-if="true">
                    <div class="mb-2" v-for="(user,index) in users.data" :key="index">
                        <avatar :src="user.image" :username="user.name" :size="35" />
                    </div>
                </div> -->

      <div
        class="w-10 bg-font-gray-15 overflow-y-auto overflow-x-hidden no-scrollbar flex flex-col relative rounded-md"
        v-if="true"
      >
        <loader :loading="loadUsers" />

        <div class="flex-1 min-h-0 py-2">
          <div
            class="mb-2 flex flex-col justify-center items-center"
            v-for="(user, index) in users.data"
            :key="index"
          >
            <div
              class="h-auto flex flex-row justify-center items-center relative cursor-pointer"
              @click="addUsers(user.id)"
            >
              <div
                v-if="if_in_commentaries(user.id)"
                class="absolute top-0 right-0 h-3 w-3 -mt-1 -mr-1 rounded-full bg-success flex flex-col justify-center items-center shadow-xl"
              >
                <i class="mdi mdi-check-bold text-white text-xxs"></i>
              </div>

              <!-- <div v-if="user.image" class="h-6 w-6 rounded-full" :style="'background-image: url(' + user.image + '); background-repeat: no-repeat; background-attachment: fixed; background-position: center;'"></div> -->
              <img
                v-if="user.image"
                class="h-6 w-6 mx-auto rounded-full bg-cover bg-center shadow-xl"
                :src="profilePhoto(user)"
              />

              <div
                v-else
                class="w-full flex flex-col justify-center items-center"
              >
                <vue-initials-img
                  :name="user.user_name"
                  class="rounded-full h-6"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="h-20 bg-module-top py-2 flex flex-col justify-between items-center"
        >
          <div
            class="h-auto flex flex-col justify-center items-center relative"
          >
            <div
              v-if="projects"
              class="h-3 w-3 -mt-1 -mr-1 rounded-full absolute bg-success top-0 right-0 flex flex-col justify-center items-center"
            >
              <i class="mdi mdi-check-bold text-xxs"></i>
            </div>
            <div
              class="h-6 w-6 rounded-full bg-filters flex flex-col justify-center items-center shadow cursor-pointer"
              @click="projects = !projects"
            >
              <span class="text-xs text-white font-semibold">PR</span>
            </div>
          </div>

          <div
            class="h-auto flex flex-col justify-center items-center relative"
          >
            <div
              v-if="oportunities"
              class="h-3 w-3 -mt-1 -mr-1 rounded-full absolute bg-success top-0 right-0 flex flex-col justify-center items-center"
            >
              <i class="mdi mdi-check-bold text-xxs"></i>
            </div>
            <div
              class="h-6 w-6 rounded-full bg-filters flex flex-col justify-center items-center shadow cursor-pointer"
              @click="oportunities = !oportunities"
            >
              <span class="text-xs text-white font-semibold">OP</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <separator v-if="$route.params.id" />
    <transition name="fadeInOut">
      <div class="" v-if="$route.params.id">
        <div class="px-2 pt-2 flex items-end">
          <textarea
            class="bg-gradient-module w-full mr-2 h-8 outline-none p-2 rounded-lg shadow-lg text-xs"
            placeholder="Send message..."
            v-model="message"
          ></textarea>
          <div class="btn-aux h-8" @click="publishComment()">
            <i class="mdi mdi-send"></i>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { state } from "@/store";

export default {
  props: ["mobile"],
  data() {
    return {
      loading: false,
      messages: [],
      users: [],
      response: null,
      page: 1,
      message: "",
      selectedDelete: false,
      selectedEdit: false,
      loadingDelete: false,
      loadUsers: false,
      user_in_commentaries: [],
      oportunities: true,
      projects: true,
    };
  },
  computed: {
    params() {
      let par = state.globalParams;
      par = { ...par, ...{ page: this.page } };
      if (this.$route.params.projectId) {
        par = {
          ...par,
          ...{ project_id: this.$route.params.projectId.toString() },
        };
      }
      return par;
    },
    userData() {
      return state.user;
    },
    commentsListen() {
      return state.commentsListen;
    },

    defaultPhoto() {
      return (
        process.env.VUE_APP_AUTH_IMG_USER + "PersonPlaceholder.96x96x32.png"
      );
    },
  },
  methods: {
    getComments() {
      this.loading = true;
      this.axios
        .get("commentary/commentaries", {
          params: { ...this.params, user_id: this.user_in_commentaries },
        })
        .then((response) => {
          this.loading = false;
          this.response = response.data;
          this.messages = response.data.data.length
            ? response.data.data.reverse()
            : [];
        });

      this.loadUsers = true;
      this.axios
        .get("commentary/users", { params: this.params })
        .then((response) => {
          this.users = response.data;
          this.loadUsers = false;
        });
    },
    publishComment(commentary) {
      this.loading = true;
      let message = commentary ? commentary.commentary : this.message;
      if (this.mobile) {
        const project_id = commentary
          ? commentary.commentable_id
          : this.$route.params.id.toString();
        let mess = {
          ...this.params,
          ...this.params,
          ...{
            commentary: message,
            project_id: project_id,
          },
        };
        if (commentary) {
          mess = {
            ...mess,
            ...{ id: commentary.id },
          };
        }
        this.axios
          .post("commentary/projects/" + project_id + "/commentaries", mess)
          .then((response) => {
            this.getComments();
            this.selectedEdit = false;
            this.message = "";
          });
        this.loading = false;
      } else {
        const project_id = commentary
          ? commentary.commentable_id
          : this.$route.params.projectId.toString();
        let mess = {
          ...this.params,
          ...this.params,
          ...{
            commentary: message,
            project_id: project_id,
          },
        };
        if (commentary) {
          mess = {
            ...mess,
            ...{ id: commentary.id },
          };
        }
        this.axios
          .post("commentary/projects/" + project_id + "/commentaries", mess)
          .then((response) => {
            this.getComments();
            this.selectedEdit = false;
            this.message = "";
          });
        this.loading = false;
      }
    },
    editComment(post) {
      let mess = {
        ...this.params,
        ...post,
      };

      this.loading = true;

      this.axios
        .post("commentary/commentaries/" + post.id + "/update", mess)
        .then((response) => {
          this.loading = false;
          this.selectedEdit = false;
          this.getComments();
        });
    },
    deleteComment(messageId) {
      // this.loading = true
      this.loadingDelete = true;
      let mess = {
        ...this.params,
        ...{ id: messageId },
      };
      this.axios
        .post("commentary/commentaries/" + messageId + "/delete", mess)
        .then((response) => {
          this.getComments();
        });
      // this.loading = false
      this.loadingDelete = false;
    },
    goto(route, sparamid) {
      // this.$router.push({ name:route, params:{id:paramid} })
      this.$router.push({ path: "" + route + "/" + paramid + "" });
      this.$emit("closeComents");
    },
    addUsers(user) {
      if (!this.user_in_commentaries.includes(user)) {
        this.user_in_commentaries.push(user);
      } else {
        for (let index = 0; index < this.user_in_commentaries.length; index++) {
          if (this.user_in_commentaries[index] == user) {
            this.user_in_commentaries.splice(index, 1);
          }
        }
      }
    },
    if_in_commentaries(user) {
      for (let index = 0; index < this.user_in_commentaries.length; index++) {
        if (this.user_in_commentaries[index] == user) {
          return true;
        }
      }
    },
    gotoproject(type) {
      if (type == "opportunity") {
        this.$router.push({ name: "OpportunitiesList" });
        this.$emit("closeComents");
      } else {
        this.$router.push({ name: "ProjectsBilling" });
        this.$emit("closeComents");
      }
    },
    getCommentsFiltereds() {
      if (this.oportunities && this.projects) {
        this.loading = true;
        this.axios
          .get("commentary/commentaries", {
            params: { ...this.params, user_id: this.user_in_commentaries },
          })
          .then((response) => {
            this.loading = false;
            this.response = response.data;
            this.messages = response.data.data.length
              ? response.data.data.reverse()
              : [];
          });
      } else {
        if (this.projects) {
          this.loading = true;
          this.axios
            .get("commentary/commentaries", {
              params: {
                ...this.params,
                user_id: this.user_in_commentaries,
                only: "projects",
              },
            })
            .then((response) => {
              this.loading = false;
              this.response = response.data;
              this.messages = response.data.data.length
                ? response.data.data.reverse()
                : [];
            });
        } else if (this.oportunities) {
          this.loading = true;
          this.axios
            .get("commentary/commentaries", {
              params: {
                ...this.params,
                user_id: this.user_in_commentaries,
                only: "opportunities",
              },
            })
            .then((response) => {
              this.loading = false;
              this.response = response.data;
              this.messages = response.data.data.length
                ? response.data.data.reverse()
                : [];
            });
        } else if (!this.oportunities && !this.projects) {
          this.messages = [];
        }
      }
    },
    profilePhoto(user) {
      return process.env.VUE_APP_AUTH_IMG_USER + user.image_name;
    },
  },
  mounted() {
    this.getComments();
  },
  watch: {
    params(n, o) {
      if (JSON.stringify(n) !== JSON.stringify(o)) {
        this.getComments();
      }
    },
    commentsListen() {
      this.getComments();
    },
    user_in_commentaries() {
      this.getComments();
    },
    projects() {
      this.getCommentsFiltereds();
    },
    oportunities() {
      this.getCommentsFiltereds();
    },
  },
};
</script>

<style>
.pointer-events-none {
  pointer-events: none !important;
}
</style>
